import { PLAYER_NEWS_SLUG_BY_TYPE } from "@/constants/playerNews";
import { ShortForm } from "@/graphql/gql/graphql";
import Link from "next/link";

interface MaybeLinkProps {
  news: ShortForm;
}

const PlayerNewsMaybeLink = ({ news }: MaybeLinkProps) => {
  if (news.newsType?.enum) {
    return (
      <Link
        href={`/${news.sport.slug}/player-news/${PLAYER_NEWS_SLUG_BY_TYPE[news.newsType.enum]}/${news.slug}`}
      >
        {news.title}
      </Link>
    );
  }
  return <>{news.title}</>;
};

export default PlayerNewsMaybeLink;
