import { ShortFormNewsTypeEnum } from '@/graphql/gql/graphql';

export interface PlayerNewsMeta {
  metaTitle: string,
  metaDescription: string,
  title: string,
  type?: ShortFormNewsTypeEnum,
}

type PlayerNewsMapType = Record<string, PlayerNewsMeta>;

export const PLAYER_NEWS_TYPES_BY_SLUG: PlayerNewsMapType = {
  injuries: {
    metaTitle: '$sport Injury Report - Latest news on $sport player injuries',
    metaDescription: 'Stay up to date with breaking news on $sport player injuries. Get insights on type of injuries, recovery updates and team impact',
    title: '$sport Player Injuries - Impact on $sport Teams',
    type: ShortFormNewsTypeEnum.Injury,
  },
  'game-recaps': {
    metaTitle: 'Key Moments and Highlights from Every $sport Game',
    metaDescription: 'Get comprehensive $sport game recaps from every $sport game and stay up to date with box scores, analysis, highlights and player performances',
    title: '$sport Game Recaps - Box Scores, Highlights & Analysis',
    type: ShortFormNewsTypeEnum.Recap },
  transactions: {
    metaTitle: 'Latest news on $sport Transactions from around the league',
    metaDescription: 'See the latest $sport league transactions from free agent signings, free agent rankings, player trades, coaching changes and more.',
    title: '$sport League Transactions - Free Agent Signings, Player Trades & More',
    type: ShortFormNewsTypeEnum.Transaction,
  },
  lineups: {
    metaTitle: 'Latest news on $sport starting lineups including depth chart changes, Fantasy and DFS',
    metaDescription: 'Stay up to date on the latest $sport lineups for your fantasy team. Expert advice on creating the best lineup to dominate your league',
    title: '$sport Lineups - Fantasy, DFS & Depth Chart Changes',
    type: ShortFormNewsTypeEnum.Lineup,
  },
  'game-updates': {
    metaTitle: 'Latest on live $sport in game stats, injuries, play by play and box scores',
    metaDescription: 'Stay up to date with the latest news, information and updates on all your favorite $sport players. Get all the insights on player performance, injuries, trades and more.',
    title: '$sport Game Scores - Live scoring and real time highlights',
    type: ShortFormNewsTypeEnum.GameUpdate },
  outlook: {
    metaTitle: 'Latest $sport Player News, Insights and Analysis',
    metaDescription: 'Stay up to date with the latest news, information and updates on all your favorite $sport players. Get all the insights on player performance, injuries, trades and more.',
    title: '$sport Outlook Updates',
    type: ShortFormNewsTypeEnum.Outlook,
  },
  default: {
    metaTitle: 'Latest $sport Player News, Insights and Analysis',
    metaDescription: 'Stay up to date with the latest news, information and updates on all your favorite $sport players. Get all the insights on player performance, injuries, trades and more.',
    title: '$sport Player News',
  },

};

export const PLAYER_NEWS_SLUG_BY_TYPE: Record<ShortFormNewsTypeEnum, string> = Object.keys(
  PLAYER_NEWS_TYPES_BY_SLUG,
).reduce((prev, slug) => {
  const { type } = PLAYER_NEWS_TYPES_BY_SLUG[slug];
  if (type) {
    return { ...prev, [type]: slug };
  }
  return { ...prev, [slug]: slug };
}, {} as Record<ShortFormNewsTypeEnum, string>);

// Helper Functions

// Helper function to find slug by type
export const findSlugByType = (typeToFind: ShortFormNewsTypeEnum): string | null => {
  let correspondingSlug: string | null = null;

  const entries = Object.entries(PLAYER_NEWS_TYPES_BY_SLUG);
  const entriesLength = entries.length;

  for (let i = 0; i < entriesLength; i += 1) {
    const [slug, { type }] = entries[i];

    if (type === typeToFind) {
      correspondingSlug = slug;
      break; // Exit the loop when the slug is foimd
    }
  }

  // Return null when the type is not found
  return correspondingSlug;
};

export const PRIMARY_REF_TYPE = {
  TEAM: 'Team',
  PLAYER: 'Player',
};
