import { detect } from 'detect-browser';

type DeviceType = {
  platform: string;
  os: string;
  isPhone: boolean;
};

export const deviceType = (): DeviceType => {
  const browser = detect();
  const os = browser && browser.os;

  switch (os) {
    case 'Windows 10':
    case 'Windows 2000':
    case 'Windows 3.11':
    case 'Windows 7':
    case 'Windows 8':
    case 'Windows 8.1':
    case 'Windows 95':
    case 'Windows 98':
    case 'Windows ME':
    case 'Windows Mobile':
    case 'Windows Server 2003':
    case 'Windows Vista':
    case 'Windows XP':
      return { platform: 'Web', os: 'Windows', isPhone: false };

    case 'Android OS':
      return { platform: 'Web', os: 'Android', isPhone: true };

    case 'iOS':
      return { platform: 'Web', os: 'iOS', isPhone: true };

    case 'Mac OS':
      return { platform: 'Web', os: 'Mac', isPhone: false };

    default:
      return { platform: 'Other', os: 'Other', isPhone: false };
  }
};

export default deviceType;
