import cx from "classnames";
import Link from "next/link";
import { Tool } from "@/graphql/gql/graphql";
import { BodyText } from "@fanduel/formation-react-components";
import { ArrowsArrowRight } from "@fanduel/formation-react-icons";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { useCallback } from "react";
import { TOOL_LINK_CLICKED } from "@/constants/amplitude";
import styles from "./tool.module.scss";

type ToolContainerProps = { condensed?: boolean };

type ToolComponentProps = Tool & ToolContainerProps & { origin: string };

const ToolComponent = ({
  description,
  title,
  url,
  condensed,
  origin,
  newWindow,
}: ToolComponentProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  const containerClass = cx(styles.container, {
    [styles.notCondensed]: !condensed,
  });

  const clickEvent = useCallback(() => {
    logAmplitudeEvent(TOOL_LINK_CLICKED, {
      "Link Text": title,
      "Link URL": url,
      Module: origin,
    });
  }, [title, url, logAmplitudeEvent, origin]);

  return (
    <div className={containerClass}>
      <Link
        target={newWindow ? "_blank" : undefined}
        onClick={clickEvent}
        href={url}
        prefetch={false}
      >
        <div className={styles.titleAndArrow}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.boxArrow}>
            <ArrowsArrowRight data-testid="arrow-right" />
          </div>
        </div>
        {description ? (
          <BodyText size="sm" className={styles.description}>
            {description}
          </BodyText>
        ) : undefined}
      </Link>
    </div>
  );
};

export default ToolComponent;
