import "twin.macro";
import Link from "@/components/Link";
import { Sport } from "@/graphql/gql/graphql";
import News from "@/components/svg/News";
import ArrowRight from "@/components/svg/ArrowRight";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { NAV_LINK_CLICKED } from "@/constants/amplitude";

type PlayerNewsHeaderProps = {
  sportName: Sport["name"];
  sportSlug: Sport["slug"];
};

const PlayerNewsHeader = ({ sportName, sportSlug }: PlayerNewsHeaderProps) => {
  const { logAmplitudeEvent } = useAmplitude();

  const headerText = `${sportName} Player News`;
  const linkText = `View all ${sportName} Player news`;

  return (
    <div tw="flex flex-wrap justify-between gap-y-4 p-4 md:px-4 md:pb-0">
      <h3 tw="flex items-center gap-2 text-base font-bold leading-5 text-grey-d4 md:text-lg">
        <News />
        <div>{headerText}</div>
      </h3>
      <Link
        onClick={() =>
          logAmplitudeEvent(NAV_LINK_CLICKED, {
            Module: "featured_news",
            "Link Text": linkText,
            "Link URL": `/${sportSlug}/player-news`,
          })
        }
        next
        href={`/${sportSlug}/player-news`}
      >
        <div tw="flex flex-nowrap items-center justify-center gap-2 text-blue hover:cursor-pointer md:px-4 md:py-[13.5px]">
          {linkText}
          <ArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default PlayerNewsHeader;
