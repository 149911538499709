import "twin.macro";
import { Loader } from "@fanduel/og-fe-library";
import { HideAtMd } from "@/components/common";
import { PlayerNewsContainerProps } from "@/types/playerNewsHome";
import Divider from "@/components/Divider";
import ErrorComponent from "@/components/empty/ErrorComponent";
import usePlayerNewsHome from "@/utils/hooks/usePlayerNewsHome";
import PlayerNewsCard from "./PlayerNewsCard";

const PlayerNewsContainer = ({ sportName }: PlayerNewsContainerProps) => {
  const { data, isLoading, isError } = usePlayerNewsHome({
    sport: sportName,
    limit: 9,
  });
  const playerNewsData = data ?? [];
  const playerNews0 = playerNewsData.slice(0, 3);
  const playerNews1 = playerNewsData.slice(3, 6);
  const playerNews2 = playerNewsData.slice(6, 9);

  if (isLoading) {
    return (
      <div
        data-testid="player-news-container"
        tw="flex h-[644px] items-center justify-center xl:h-[294px]"
      >
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div
        data-testid="player-news-container"
        tw="flex h-[407px] items-center justify-center xl:h-[294px]"
      >
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div data-testid="player-news-container">
      <div tw="grid grid-cols-1 xl:grid-cols-3 xl:gap-6 xl:px-4 xl:pb-4">
        <div tw="col-span-1">
          {playerNews0.map((news, idx) => (
            <div key={news?.id}>
              <PlayerNewsCard news={news} sportName={sportName} />
              {idx < playerNews0.length - 1 && <Divider color="light" />}
              {idx === playerNews0.length - 1 && (
                <HideAtMd>
                  <Divider color="light" />
                </HideAtMd>
              )}
            </div>
          ))}
        </div>
        <div tw="col-span-1">
          {playerNews1.map((news, idx) => (
            <div key={news?.id}>
              <PlayerNewsCard news={news} sportName={sportName} />
              {idx < playerNews1.length - 1 && <Divider color="light" />}
              {idx === playerNews1.length - 1 && (
                <HideAtMd>
                  <Divider color="light" />
                </HideAtMd>
              )}
            </div>
          ))}
        </div>
        <div tw="col-span-1">
          {playerNews2.map((news, idx) => (
            <div key={news?.id}>
              <PlayerNewsCard news={news} sportName={sportName} />
              {idx < playerNews0.length - 1 && <Divider color="light" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlayerNewsContainer;
