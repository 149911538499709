import Link from "next/link";
import { Sport } from "@/graphql/gql/graphql";
import { Button } from "@fanduel/og-fe-library";
import Image from "@/components/Image";
import styles from "./featuredSportHeader.module.scss";

type LeagueSectionHeaderProps = {
  sportName: Sport["name"];
  sportSlug: Sport["slug"];
  sportLogo?: Sport["logo"];
  title?: string;
};

const FeaturedSportHeader = ({
  sportName,
  sportSlug,
  sportLogo,
  title,
}: LeagueSectionHeaderProps) => {
  const buttonText = `View all ${sportName} news`;

  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>
        {sportLogo?.url && (
          <Image
            style={{
              display: "inline",
              height: "30px",
              marginRight: ".5rem",
              width: "auto",
            }}
            width={30}
            height={30}
            alt={sportName}
            src={sportLogo?.url}
          />
        )}
        {title ?? sportName}
      </h3>
      <Link href={`/${sportSlug}`} className={styles.link}>
        <Button
          ariaLabelText={buttonText}
          buttonText={buttonText}
          buttonStyle="Naked"
          rightIconName="arrows.right"
          iconColor="colorBaseBlueDarken2"
          className={styles.button}
        />
      </Link>
    </div>
  );
};

export default FeaturedSportHeader;
