import { useMemo } from "react";
import {
  Article,
  ContentSportsEnum,
  SelectedSport,
} from "@/graphql/gql/graphql";
import Template3Column from "@/components/templates/sport/Template3Column";
import FeaturedSportHeader from "./FeaturedSportHeader";
import PlayerNews from "../playerNews/PlayerNews";
import styles from "./featuredSport.module.scss";

// temporary removal until we add more player news content
const RF_PLAYER_NEWS = false;

type FeaturedSportProps = {
  selectedSport: SelectedSport;
  title?: string;
};

const FeaturedSport = ({ selectedSport, title }: FeaturedSportProps) => {
  const { sport, featuredArticles, tool } = selectedSport;
  const [spotlightArticle, ...remainingArticles] =
    (featuredArticles as Article[]) ?? [];
  const [col2Articles, col3Articles] = useMemo(() => {
    const cutOff = tool ? 9 : 10;
    return [
      remainingArticles?.slice(0, 5),
      remainingArticles?.slice(5, cutOff),
    ];
  }, [remainingArticles, tool]);

  if (!featuredArticles) {
    return null;
  }

  const { logo, name: sportName, slug: sportSlug, hasPlayerUpdates } = sport;

  return (
    <div>
      <FeaturedSportHeader
        sportName={sportName}
        sportSlug={sportSlug}
        sportLogo={logo}
        title={title}
      />
      <div className={styles.content}>
        <div className={styles.boxTemplate}>
          <Template3Column
            col1Article={spotlightArticle}
            col2Articles={col2Articles}
            col3Articles={col3Articles}
            sportName={sportName}
            tool={tool}
          />
        </div>
        {RF_PLAYER_NEWS
          ? hasPlayerUpdates && (
              <PlayerNews
                sportName={sportName as ContentSportsEnum}
                sportSlug={sportSlug}
              />
            )
          : null}
      </div>
    </div>
  );
};

export default FeaturedSport;
